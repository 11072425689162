import React from "react";
import Footnote from "../components/Footnote";
import "./auditionStyles.css";
import AlumPic from "../images/Alumni.png";
import ImageBox from "../components/ImageBox";

const leftColumn = ["Jack Spalding", "Maxwell Banks" ,"Fiona Gleeson"  ,"Zella Roth", "D’Angelo Reyes", "Keegan Huth", "Jackie Morales" ,
                    "Elliot Peters" ,"Grace Ballard" ,"Madeleine Smyth" ,"Wylie Hughs", "Zoe Fassler" ,"Garrett Lamoureux" ,"Bennett McKay" ,
                    "John Chapman", "Dalton Varvell", "Tim Gachot", "Kailey Zeiders", "Maya Sjoblom-Powell", "Ryan Kain", "Kyle Bezio", "James Smith"];

const midColumn = ["Paul Levinson-Muth", "Grace Trotta", "Chris Washburn/Gary Wishbone", "Jade Sigmund",  "Anna Bubier" , "Braden Wicker", 
                   "Ross Levine", "Ryan Clahan", "Jack Boudreau", "Zach Lax", "Austin Kenwood", "Sasha Smolgovsky", "Jacob Corsaro", "Jeff Caldeira", 
                   "Austin Barber", "Cam Gurley", "Anders Griffin", "Cherry Tsang", "Megan Hunt", "Ian Cope", "Justin Scabarozi", "RJ Calub"];

const rightColumn = ["Zach Hoffman", "Bri Rodebaugh", "Hailey-Rae Rose", "Katryna Fogel", "Nick Carrell", "Connor Sheehan", "Meghan Hornbacker", "Travis Cox", 
                    "Brandon Cunnane", "Lilly Conboy", "Nikki Mariviglia", "Tim Manion", "Kolby Hatch", "Leah Van der Heide", "Logan McGinley",
                    "Ben Pawlik", "Nick Corcores", "Caeden Schlosser", "Rhagav Bhasin", "Charlie Roskins", "Nash Rothle", "Alex Zavala"];

const Alumni = () => {
	return (
        <div>
            <div style={{marginTop: "0.5vw"}}>
                <h1 className="mainHeader">
                    Remember the Fallen
                </h1>
            </div>
            <div style={{textAlign: "center"}}>
                <ImageBox height={"20vw"} width={"70%"} img={AlumPic} alt={"Picture of Past Alumni"}></ImageBox>
            </div>
            <div>
                <h1 style={{fontSize: "2vw", textAlign: "center", color: "white", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill"}}>
                    Our team has been around for over 20 years with tons of talented members!
                </h1>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "row", textAlign: "center"}}>
                <h3 style={{flex: 1}}>
                    {leftColumn.map((name)=> <div style={{fontSize : "2vw"}}>{name}</div>)}
                </h3>
                <h3 style={{flex: 1}}>
                    {midColumn.map((name)=> <div style={{fontSize : name === "Ian Cope" ? "2.2vw": "2vw"}}>{name}</div>)}
                </h3>
                <h3 style={{flex: 1}}>
                    {rightColumn.map((name)=> <div style={{fontSize : "2vw"}}>{name}</div>)}
                </h3>
            </div>
            <div>
                <h1 style={{fontSize: "2vw", textAlign: "center", color: "white", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill"}}>
                    If you can help us expand our alumni records, please contact us at <em style={{color: "gold"}}>smileandnodcalpoly@gmail.com</em>.
                </h1>
            </div>
            <Footnote/>
        </div>
	);
};

export default Alumni;
