// This page displays the current members of the team

import React from "react";
import Footnote from "../components/Footnote";
import MemberGrid from "../components/MemberGrid";
import alexHayden from "../images/alex-hayden.jpeg"
import colinElman from "../images/colin-elman.jpeg"
import darcieShugart from "../images/darcie-shugart.jpg"
import dylanBaurle from "../images/dylan-baurle.jpeg"
import faithKrank from "../images/faith-krank.jpeg"
import hueLykes from "../images/hue-lykes.jpeg"
import meganRobinson from "../images/megan-robinson.jpeg"
import miriamSullivan from "../images/miriam-sullivan.jpeg"
import rubyIntner from "../images/ruby-intner.jpeg"
import sofRakowski from "../images/sof-rakowski.jpeg"
import teganSchlosser from "../images/tegan-schlosser.jpg"
import wyattGouger from "../images/wyatt-gouger.jpeg"
import zachBerston from "../images/zach-berston.jpg"
import cameronMorrow from "../images/cameron-morrow.jpeg"
import johnDevrees from "../images/john-devrees.png"
import miraWakefield from "../images/mira-wakefield.png"
import graceBennett from "../images/grace-bennett.png"
import nickBaham from "../images/nick-baham.png"
import andiCasey from "../images/andi-casey.jpg"
import mickeyDanza from "../images/mickey-danza.jpg"
import isaiahCovarrubias from "../images/isaiah-covarrubias.jpg"
// import dummyMember from "../images/dummy-member.png"

import "./auditionStyles.css";

const member_data = [
    {first_name:"Alex", middle_name:"Punishment", last_name:"Hayden", img:alexHayden},
    {first_name:"Cameron", middle_name:"Caffeine Craver", last_name:"Morrow", img:cameronMorrow},
    {first_name:"Colin", middle_name:"Burger Boy", last_name:"Elman", img:colinElman},
    {first_name:"Darcie", middle_name:"Hogman is not a cult", last_name:"Shugart", img:darcieShugart},
    {first_name:"Dylan", middle_name:"Degenerate", last_name:"Baurle", img:dylanBaurle},
    {first_name:"Faith", middle_name:"SpongeBob Cadillac", last_name:"Krank", img:faithKrank},
    {first_name:"Hue", middle_name:"Carbonation Connoisseur", last_name:"Lykes", img:hueLykes},
    {first_name:"Megan", middle_name:"Ankle-Biter", last_name:"Robinson", img:meganRobinson},
    {first_name:"Miriam", middle_name:"Dual Citizen", last_name:"Sullivan", img:miriamSullivan},
    {first_name:"Ruby", middle_name:"Mr. President", last_name:"Intner", img:rubyIntner},
    {first_name:"Sof", middle_name:"The Bug", last_name:"Rakowski", img:sofRakowski},
    {first_name:"Tegan", middle_name:"Let Them Eat Cake", last_name:"Schlosser", img:teganSchlosser},
    {first_name:"Wyatt", middle_name:"Smart, Funny, Handsome, and Humble", last_name:"Gouger", img:wyattGouger},
    {first_name:"Zach", middle_name:"Zach Berston", last_name:"Berston", img:zachBerston},
    {first_name:"John", middle_name:"Wine into Water", last_name:"Devrees", img:johnDevrees},
    {first_name:"Grace", middle_name:"Professional Lobotomist", last_name:"Bennett", img:graceBennett},
    {first_name:"Mira", middle_name:"Diversity Hire", last_name:"Wakefield", img:miraWakefield},
    {first_name:"Nick", middle_name:"Soil Thief", last_name:"Baham", img:nickBaham},
	{first_name: "Andrea", middle_name: "Fighter Not a Lover", last_name: "Casey", img:andiCasey},
	{first_name: "Mickey", middle_name: "Sonic Mpreg Fan", last_name: "Danza", img:mickeyDanza},
	{first_name: "Isaiah", middle_name: "The Princess", last_name: "Covarrubias", img:isaiahCovarrubias},
]

const noob_data = [
]
const Members = () => {
	return (
		<div style={{marginTop: "0.5vw"}}>
			<div>
				<h1 className="mainHeader">
					Current Members
				</h1>
				<MemberGrid memberData={member_data}/>
			</div>
			<div>
			{/* <h1 className="mainHeader">
					Our Noobs!
				</h1> */}
				<MemberGrid memberData={noob_data}/>
			</div>
			<Footnote/>
		</div>
	);
};

export default Members;
