// This is the default home page for SMOD Site, First visit goes here

import React from "react";
import Footnote from "../components/Footnote";
import ImageBox from "../components/ImageBox";
import "./auditionStyles.css";
import Banner from "../images/WebsiteBanner.png";
import PreviewCarousel
 from "../components/PreviewCarousel";
import InstagramButton from "../components/InstagramButton";
import ImprovFormsCarousel from "../components/ImprovFormsCarousel";

const style = {
	all : {
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative"
	}
}

const TicketPrice = 5;

const Preface = "Smile and Nod is Cal Poly's BEST (and only) improv team! Our team is dedicated towards spreading laughter and hogging attention, using the suggestions YOU give to create a once in a lifetime show! We use no scripts, no preparation, and ideas generated on the spot in order to create hilarious scenes that will never be seen again.";

const Home = () => {
	return (
		<div style={style.all}>
			<div style={{paddingTop: 20}}>
				<ImageBox height={"22vw"} width={"100%"} img={Banner} alt={"Banner"}/>
			</div>				
			<div style={{ margin: "auto", textAlign: 'center', width: "70%", paddingTop: 20 }}>
				<PreviewCarousel></PreviewCarousel>
			</div>
			<div style={{paddingTop: 20, display: "flex", justifyContent: "center"}}>
				<InstagramButton height={"5vw"}></InstagramButton>
			</div>			
			<div style={{paddingTop: "3vw"}}>
				<h1 style={{textAlign: 'center', fontSize: "3vw", fontWeight: "bold",  WebkitTextStroke: "0.3vw black", paintOrder: "stroke fill", color: "white"}}>
					What is "Smile And Nod"?
				</h1>
				<h2 style={{textAlign: 'center', marginLeft: "15%", marginRight: "15%", fontSize: "2.5vw"}}>
					{Preface}
				</h2>
				<h1 style={{textAlign: 'center', fontSize: "3vw", fontWeight: "bold",  WebkitTextStroke: "0.3vw black", paintOrder: "stroke fill", color: "white"}}>
					When/Where Can I Watch A Show?
				</h1>
				<h2 style={{textAlign: 'center', marginLeft: "15%", marginRight: "15%", fontSize: "2.5vw"}}>
					We have shows every Saturday at 7pm in Philips Hall (PAC 124).
				</h2>
				<h1 style={{textAlign: 'center', fontSize: "3vw", fontWeight: "bold",  WebkitTextStroke: "0.3vw black", paintOrder: "stroke fill", color: "white"}}>
					What Does An Improv Show Look Like?
				</h1>
			</div>
			<div style={{ margin: "auto", textAlign: 'center', width: "50%", paddingTop: 20 }}>
				<ImprovFormsCarousel></ImprovFormsCarousel>
			</div>
			<div>
				<h1 style={{textAlign: 'center', fontSize: "3vw", fontWeight: "bold",  WebkitTextStroke: "0.3vw black", paintOrder: "stroke fill", color: "white", paddingTop: 20}}>
					Don't Trust We're Funny? Check Out These "Real" Reviews!
				</h1>
				<div style={{display: "flex", flexDirection: "row"}}>
					<div style={{flex: 1}}>
						<h2 style={{ margin: "auto", textAlign: 'center', paddingTop: 20, color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw" }}>
							"Only ${TicketPrice} for this much fun? What a deal!"
						</h2>
						<h2 style={{ margin: "auto", textAlign: 'center', color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw"}}>
							-Anonymous Source
						</h2>
					</div>
					<div style={{flex: 1}}>
						<h2 style={{ margin: "auto", textAlign: 'center', paddingTop: 20, color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw" }}>
							"Philips Hall is my favorite place to watch improv!"
						</h2>
						<h2 style={{ margin: "auto", textAlign: 'center', color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw"}}>
							-Total Stranger
						</h2>
					</div>
					<div style={{flex: 1}}>
						<h2 style={{ margin: "auto", textAlign: 'center', paddingTop: 20, color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw" }}>
							"I can't believe I get to watch this EVERY Saturday at 7:00pm!"
						</h2>
						<h2 style={{ margin: "auto", textAlign: 'center', color: "gold", WebkitTextStroke: "0.2vw black", paintOrder: "stroke fill", fontSize: "2.5vw"}}>
							-Unpaid Actor
						</h2>
					</div>
				</div>
			</div>
		    <Footnote/>
		</div>
	);
};

export default Home;
