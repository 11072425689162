import './App.css';
import Navbar from "./components/Navbar";

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import Home from "./pages";
import FAQ from "./pages/FAQ";
import Auditions from "./pages/auditions";
import Members from "./pages/members";
import Contact from "./pages/contact";
import Alumni from "./pages/alumni";

function App() {

  if(window.location.hash) {
    window.history.replaceState("", document.title, window.location.pathname);
  } 

  //change background color here 
  var backgroundcolor = "#6BC87D";

  //find min height of page for background color
  var body = document.body,
  html = document.documentElement;
  var height = Math.max( body.scrollHeight, body.offsetHeight, 
                     html.clientHeight, html.scrollHeight, html.offsetHeight );

  //set background color for root
  body.style.backgroundColor= backgroundcolor;
  body.style.margin= 0;
  return (
    <div style={{minHeight : height}}>
      <Router>
              <Navbar />
              <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/FAQ" element={<FAQ />} />
                  <Route
                      path="/contact"
                      element={<Contact />}
                  />
                  <Route path="/auditions" element={<Auditions />} />
                  <Route
                      path="/members"
                      element={<Members />}
                  />
                  <Route path="/alumni" element={<Alumni />} />
              </Routes>
          </Router>
        </div>
  );
}

export default App;
