import React from "react";
import Member from "../components/Member";

const styles = {
    grid : {
        display: "flex",
        flexDirection: "row",
        margin: "auto",
        width: "70%",
        flexWrap: "wrap",
        marginTop: "2vw",
        columnGap:"16vw",
        rowGap:"1vw",
        flex: 1
    }
}

function MemberGrid(props) {
    const members_objects = props.memberData.map(member => (
        <Member {...member}/>
    ))
    return (
        <div style={styles.grid}>
            {members_objects}
         </div>
    );
}

export default MemberGrid;