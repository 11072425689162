// Auditions Page

import React from "react";
import Footnote from "../components/Footnote";
import CouldBeYou from "../components/CouldBeYou";

import './auditionStyles.css';

const styles = {
	steps : {
			  fontSize: "3vw", 
		      textAlign: "center", 
			  color: "gold", 
			  WebkitTextStroke: "0.4vw black", 
			  paintOrder: "stroke fill"
			},
	descriptions : {
			  fontSize: "2vw",
			  textAlign: "center"
	}
}

const Auditions = () => {
	return (
		<div>
			<div style={{marginTop: "0.5vw", marginLeft: "10%", marginRight: "10%", textAlign: "center"}}>
				<h1 className="mainHeader">Do You ...</h1>
				<h2 style={styles.steps}> Crave the spotlight? </h2>
				<h2 style={styles.steps}> Love to make others laugh? </h2>
				<h2 style={styles.steps}> Want to do as seen on stage? </h2>
				<h3 style={styles.descriptions}>
					If so, come to one of our auditions at the beginning of each quarter!
					Smile and Nod aims to be the best it can be, so we only take a select train them to be ready to join our performances. No improv
					experience is needed to audition and we are looking for people of all different backgrounds and humors, so come give it a try!
					After all, becoming a part of our team is as easy as 1, 2, 3!</h3>
			</div>
			<div style={{margin: "auto", width:"70%"}}>
				<h2 style={styles.steps}>1. Auditions</h2>
				<h3 style={styles.descriptions}>The first step is to come to our public auditions we hold at the beginning of every quarter! You will fill out an audition card with your contact info, 
					be taught a few essential improv tips, learn the short-form games you will be playing, and get the chance to show off your skills on stage! Audition dates and times are posted on our Instagram
					every quarter, so make sure to <a href="https://www.instagram.com/smileandnodcp?igsh=MzRlODBiNWFlZA==">follow us on Instagram</a> to stay up to date! If auditions sound scary, go to our pre-audition workshop where you can practice all of the games ahead of time 
					with current team members. For more details, join our <a href="https://groupme.com/join_group/63039027/PT35qZCs">GroupMe</a>.</h3>
				<h2 style={styles.steps}>2. Callbacks</h2>
				<h3 style={styles.descriptions}> After auditions, the team spends hours deliberating on who makes callbacks. By the end of the night, the team some auditioners back for a callback! At the callback,
					you will get to do improv with the team to see how well we compliment each other's styles and get to see how you handle long-form improv!
				</h3>
				<h2 style={styles.steps}>3. Noob Quarter</h2>
				<h3 style={styles.descriptions}>Congratulations, you made the team! Before you can be in our shows, you have to practice during your "Noob Quarter". You are expected to make all practices and shows if possible,
					as we need to ensure our new members are ready and confident before we make them perform. During this time you will also learn our teams traditions, gain technical expertise needed to run a show, 
					and get close with your new improv family!
				</h3>
				<h1 style={styles.steps}>So Stop Waiting, Our Next Member Could Be ...</h1>
				<div style={{textAlign: "center"}}>
					<CouldBeYou/>
				</div>
			</div>
			<Footnote/>
		</div>
		)
};

export default Auditions;
